exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-cases-aracor-index-jsx": () => import("./../../../src/pages/cases/aracor/index.jsx" /* webpackChunkName: "component---src-pages-cases-aracor-index-jsx" */),
  "component---src-pages-cases-belovy-index-jsx": () => import("./../../../src/pages/cases/belovy/index.jsx" /* webpackChunkName: "component---src-pages-cases-belovy-index-jsx" */),
  "component---src-pages-cases-feodal-index-jsx": () => import("./../../../src/pages/cases/feodal/index.jsx" /* webpackChunkName: "component---src-pages-cases-feodal-index-jsx" */),
  "component---src-pages-cases-index-jsx": () => import("./../../../src/pages/cases/index.jsx" /* webpackChunkName: "component---src-pages-cases-index-jsx" */),
  "component---src-pages-cases-qyral-index-jsx": () => import("./../../../src/pages/cases/qyral/index.jsx" /* webpackChunkName: "component---src-pages-cases-qyral-index-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-services-cross-platform-development-jsx": () => import("./../../../src/pages/services/cross-platform-development.jsx" /* webpackChunkName: "component---src-pages-services-cross-platform-development-jsx" */),
  "component---src-pages-services-mobile-development-jsx": () => import("./../../../src/pages/services/mobile-development.jsx" /* webpackChunkName: "component---src-pages-services-mobile-development-jsx" */),
  "component---src-pages-services-quality-assurance-optimization-jsx": () => import("./../../../src/pages/services/quality-assurance-optimization.jsx" /* webpackChunkName: "component---src-pages-services-quality-assurance-optimization-jsx" */),
  "component---src-pages-services-technological-startup-partner-jsx": () => import("./../../../src/pages/services/technological-startup-partner.jsx" /* webpackChunkName: "component---src-pages-services-technological-startup-partner-jsx" */),
  "component---src-pages-services-troubleshooting-business-scaling-jsx": () => import("./../../../src/pages/services/troubleshooting-business-scaling.jsx" /* webpackChunkName: "component---src-pages-services-troubleshooting-business-scaling-jsx" */),
  "component---src-pages-services-web-development-jsx": () => import("./../../../src/pages/services/web-development.jsx" /* webpackChunkName: "component---src-pages-services-web-development-jsx" */)
}

